import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../api/commonAction";
import { useTheme } from './ThemeContext';

export default function ThemeToggle(props) {
  // const { theme, toggleTheme } = useTheme();
  const theme = useSelector((state) => state.theme);

  const dispatch = useDispatch()

  useEffect(() => {
    // Apply the current theme when the component mounts
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <button className="toggletheme_btn"
      //  onClick={toggleTheme}
      onClick={() =>
        setTheme(dispatch, theme == "light" ? "dark" : "light")
      }
    >{theme === "dark" ? <i class="bi bi-sun-fill"></i> : <i class="bi bi-moon-fill"></i>}</button>
  );
}
