import {
    setTheme as setThemeLocal,
    changeTradeTheme as changeTradeThemeLocal,
} from "../lib/localStorage";

import {

    SET_THEME,
} from "../constant";


export const setTheme = async (dispatch, theme) => {
    await setThemeLocal(theme);
    dispatch({
        type: SET_THEME,
        theme,
    });
    return true;
};
